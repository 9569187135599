import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(arreglo: any[], texto: string, columna: string): any[] {

    if ( texto === '' || texto === 'todos') {
      return arreglo;
    } else {

      texto = texto.toLocaleLowerCase();

      return arreglo.filter( item => {
        return item[columna].toLowerCase()
                   .includes(texto);
      });
    }

    return arreglo;
  }

}
